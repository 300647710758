import React, { useState } from 'react';
import arrowIcon from '../../../assets/images/FirstFold/arrow-icon.svg';
import { Button, Col, Input, Row, Space, Typography, notification } from "antd";


import './style.scss';

const SixthFold_v1 = ()=>{
  const [email, setEmail] = useState('')

  const handleSubscribe =() =>{
    let pattern = /^(?!.*(?:''|\.\.))[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    let finalObj={
      "email":email,
      identifier:'FlixStock'
    }
    if(pattern.test(email)){
      fetch("https://api-pegasus.flixstudio.io/limited/api/v1/inquiry/newSubscriptionAlert", {
        // Return promise
        method: "POST",
        // withCredentials: true,
        // credentials: 'include',
        body: JSON.stringify(finalObj),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            notification.success({
              message:"Request Successful",
            })
          }
        })
        .then(
          (result) => {},
          (error) => {
            console.log(error);
          }
        );
    }
    else{
      notification.info({
        message:"Error",
        description:"Please enter a valid email address."
      })
    }
  }

    return(
        <div className='sixth_fold_v1'>
            <div className='container'>
                <div className='sixth_wrapper'>
                <div className='signup'>
                    <h2 className='signup_title'>Sign up for updates</h2>
                    <p className='signup_text'>Enter your details below to receive the
                    latest updates from FlixStock</p>
                    <form>
                        <div className='email_wrapper'>
                            <Input placeholder="Enter your email*" className='email_input' onChange={(e)=>setEmail(e.target.value)}/>
                            <Button className='button_section' onClick={()=>handleSubscribe()}>Sign Up <img src={arrowIcon} alt=""/></Button>
                        </div>
                         <p className='subscribe'>By subscribing, you agree to receive emails from FlixStock.</p>
                    </form>
                </div>
                </div>
            </div>
        </div>
    )
}

export default SixthFold_v1;