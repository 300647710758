 let useCaseData = [
  {
    name: "model",
    brands: [
      "Bulk product photoshoots to capture large numbers of product images quickly and cost-effectively",
      "Photoshoots for new product launches immediately ",
    ],
    marketplace: [
      "Bulk photoshoots for multiple sellers' product images",
      "second hand marketplaces can heavily reduce their cost of cataloging keeping a base image standard ",
    ],
    retailers: [
      "Bulk photoshoots for product images for the website and marketing materials",
      "Customized photoshoots for seasonal promotions and campaigns",
      "Photoshoots for new product launches and collections",
    ],
  },
  {
    name: "edit",
    brands: [
      "Removing background from images for a clean and professional product presentation",
      "Retouching product images to enhance the appearance of apparel products",
      "Resizing images for consistent product image sizes on the website. ",
      "Automated editing workflows for high volume season",
    ],
    marketplace: [
      "Editing product images to ensure consistency across all sellers' listings",
      " Resizing images to meet marketplace specifications for image sizes and dimensions",
    ],
    retailers: [
      "Enhancing product images to make products more appealing and drive conversions",
      "Consistent image editing to maintain brand image across all product images",
      "Quick turnaround time for high volume image editing needs during peak sales seasons",
    ],
  },
  {
    name: "studio",
    brands: [
      "Outsource end to end photography services",
      "Slection of brand specifc model",
      " Use of flixstudio software to have more control and visibility of the shoot ",
      "A lot more customization as per brands needs ",
    ],
    marketplace: [
      "Tie up with flixstudio can give immediate access of photoshoot to Marketplace sellers",
    ],
    retailers: [
      "Outsource end to end photography services",
      "Slection of brand specifc model as there are multi brands under them ",
      "Use of flixstudio software to have more control and visibility of the shoot ",
      "A lot more Brand specific customization as per brands needs ",
    ],
  },
  {
    name: "3d",
    brands: [
      "3D product images for an immersive shopping experience",
      "360-degree product images to showcase product details and dimensions",
      "Cut sampling cost to go green",
      "Open a metaverse store",
      "Virtual try-on options to allow customers to visualize products on themselves on the website",
    ],
    marketplace: [
      "3D product images for an immersive shopping experience across all sellers' listings",
      "360-degree product images to showcase product details and dimensions across all sellers' listings",
      "Virtual try-on options to allow customers to visualize products on themselves for all sellers' listings",
    ],
    retailers: [
      "3D product images for an immersive shopping experience on the website",

      "360-degree product images to showcase product details and dimensions on the website",

      "Virtual try-on options to allow customers to visualize products on themselves on the website",
    ],
  },
  {
    name: "lite",
    brands: [
      "A/B testing of product images with varied model for improved conversions",

      "Self-served image editing for quicker turnaround times and cost savings for all Smaller brands or new brands ",

      "Faster catalog making for B2B sales ",

      "Revamp old imagery with new models to give refreshing catalog to your customer",
    ],
    marketplace: [
      "A/B testing of product images for improved conversions across all sellers' listings",

      "Revamp old imagery with new models to give refreshing catalog to your customer ",
    ],
    retailers: [
      "A/B testing of product images for improved conversions on the website",
      "Self-served image editing for quicker turnaround times and cost savings on the website",
      "Product image processing for product image consistency on the website",
      "Revamp old imagery with new models to give refreshing catalog to your customer",
    ],
  },
  {
    name: "plus",
    brands: [
      "Controlled creative and marketing image generation for social media and marketing campaigns and offline campaigns ",

      "Bulk creative generation for multiple usages (Marketing, social media, posters etc) at once ",

      "Generate multiple creatives to do A/B testing with same garments but on different backgrounds etc ",
    ],
    marketplace: [
      "Controlled creative and marketing image generation for social media and marketing campaigns and offline campaigns ",

      "Bulk creative generation for multiple usages (Marketing, social media, posters etc) at once ",

      "Generate multiple creatives to do A/B testing with same garments but on different backgrounds etc ",
    ],
    retailers: [
      "Controlled creative and marketing image generation for social media and marketing campaigns and offline campaigns ",

      "Bulk creative generation for multiple usages (Marketing, social media, posters etc) at once ",

      "Generate multiple creatives to do A/B testing with same garments but on different backgrounds etc ",
    ],
  },
];
export {useCaseData}
