import React from 'react';
import FirstFold_v1 from './FirstFold_v1';
import SecondFold_v1 from './SecondFold_v1';
import ThirdFold_v1 from './ThirdFold_v1';
import FourthFold_v1 from './FourthFold_v1';
import FifthFold_v1 from './FifthFold_v1';
import SixthFold_v1 from './SixthFold_v1';
import './style.scss';

const Home = (props) =>{

    const getFirstFold = () =>{
        return <FirstFold_v1 setBookingFlag={props.setBookingFlag}/>
    }

    const getSecondFold = () =>{
        return <SecondFold_v1/>
    }

    const getThirdFold = () =>{
        return <ThirdFold_v1/>
    }

    const getFourthFold = () =>{
        return <FourthFold_v1/>
    }

    const getFifthFold = () =>{
        return <FifthFold_v1/>
    }

    const getSixthFold = () =>{
        return <SixthFold_v1/>
    }

    return(
        <div className='home_page'>
            {getFirstFold()}
            {getSecondFold()}
            {getThirdFold()}
            {getFourthFold()}
            {getFifthFold()}
            {getSixthFold()}
        </div>
    )
}

export default Home;