import React from "react";
import { Typography } from "antd";
const {Title} = Typography

const PolicyPage = () => {
  return (
    <div className="policy_page text-left">
      <div id="privacy" className="privacy_box mb-4">
        <Title level={1}>Privacy Policy</Title>
        <p className=" para_gap">
          This privacy policy sets out how FlixStock uses and protects any
          information that you give FlixStock when you use this website.
        </p>
        <p className=" para_gap">
          FlixStock is committed to ensuring that your privacy is protected.
          Should we ask you to provide certain information by which you can be
          identified when using this website, and then you can be assured that
          it will only be used in accordance with this privacy statement.
        </p>

        <p className=" para_gap">
          FlixStock may change this policy from time to time by updating this
          page.
        </p>

        <p className="para_gap">
          You should check this page from time to time to ensure that you are
          happy with any changes.
        </p>
      </div>

      <div id="collect" className="collect_box mb-4">
        <Title level={3}>What we collect</Title>
        <Title level={4}>We may collect the following information:</Title>
        <ul className="para_gap">
          <li>name and job title</li>

          <li>contact information including email address</li>

          <li>
            demographic information such as postcode, preferences and interests
          </li>

          <li>other information relevant to customer surveys and/or offers</li>
        </ul>
      </div>

      <div id="information" className="information_box mb-4">
        <Title level={3}>What we do with the information we gather</Title>
        <Title level={4}>
          We require this information to understand your needs and provide you
          with a better service, and in particular for the following reasons:
        </Title>

        <ul className="para_gap">
          <li>
            We may use the information to improve our products and services.
          </li>

          <li>
            We may periodically send promotional email about new products,
            special offers or other information which we think you may find
            interesting using the email address which you have provided.
          </li>

          <li>
            From time to time, we may also use your information to contact you
            for market research purposes. We may contact you by email, phone,
            fax or mail. We may use the information to customize the website
            according to your interests.
          </li>
        </ul>
      </div>

      <div id="images" className="useImage_box mb-4">
        <Title level={3}>Use of your images</Title>
        <p className=" para_gap">
          FlixStock is committed to you that it won’t use any of your image(s)
          commercially and even for its own promotional purposes including
          publishing in the website as a sample. Except for the free trial
          images which we reserve the right to use for our own promotional
          purposes only i.e. publishing in the website as our work sample. You
          can also stop such use of the trial image(s) simply by sending an
          email to contact@flixstock.com We may also request you for your
          permission to use your regular photos into our website as our work
          sample. But that is also entirely up to you whether or not you would
          give us permission to do so.
        </p>
      </div>

      <div id="security" className="security_box mb-4">
        <Title level={3}>Security</Title>
        <p className=" para_gap">
          We are committed to ensuring that your information is secure. In order
          to prevent unauthorised access or disclosure we have put in place
          suitable physical, electronic and managerial procedures to safeguard
          and secure the information we collect online.
        </p>
      </div>

      <div id="cookies" className="cookies_box mb-4">
        <Title level={3}>How we use cookies</Title>
        <p className=" para_gap">
          A cookie is a small file which asks permission to be placed on your
          computer’s hard drive. Once you agree, the file is added and the
          cookie helps analyze web traffic or lets you know when you visit a
          particular site. Cookies allow web applications to respond to you as
          an individual. The web application can tailor its operations to your
          needs, likes and dislikes by gathering and remembering information
          about your preferences.
        </p>

        <p className=" para_gap">
          We use traffic log cookies to identify which pages are being used.
          This helps us analyse data about web page traffic and improve our
          website in order to tailor it to customer needs. We only use this
          information for statistical analysis purposes and then the data is
          removed from the system.
        </p>
        <p className=" para_gap">
          Overall, cookies help us provide you with a better website, by
          enabling us to monitor which pages you find useful and which you do
          not. A cookie in no way gives us access to your computer or any
          information about you, other than the data you choose to share with
          us.
        </p>

        <p className="para_gap">
          You can choose to accept or decline cookies. Most web browsers
          automatically accept cookies, but you can usually modify your browser
          setting to decline cookies if you prefer. This may prevent you from
          taking full advantage of the website.
        </p>
      </div>

      <div id="websites" className="website_box mb-4">
        <Title level={3}>Links to other websites</Title>
        <p className="para_gap">
          Our website may contain links to other websites of interest. However,
          once you have used these links to leave our site, you should note that
          we do not have any control over that other website. Therefore, we
          cannot be responsible for the protection and privacy of any
          information which you provide whilst visiting such sites and such
          sites are not governed by this privacy statement. You should exercise
          caution and look at the privacy statement applicable to the website in
          question.
        </p>
      </div>

      <div id="personalInfo" className="personalInfo_box">
        <Title level={3}>Controlling your personal information</Title>
        <Title level={4}>
          You may choose to restrict the collection or use of your personal
          information in the following ways:
        </Title>
        <ul className="para_gap">
          <li>
            whenever you are asked to fill in a form on the website, look for
            the box that you can click to indicate that you do not want the
            information to be used by anybody for direct marketing purposes{" "}
          </li>

          <li>
            if you have previously agreed to us using your personal information
            for direct marketing purposes, you may change your mind at any time
            by writing to or emailing us at contact@flixstock.com.
          </li>
        </ul>

        <p className=" para_gap">
          We will not sell, distribute or lease your personal information to
          third parties unless we have your permission or are required by law to
          do so. We may use your personal information to send you promotional
          information about third parties which we think you may find
          interesting if you tell us that you wish this to happen.
        </p>
        <p className=" para_gap">
          You may request details of personal information which we hold about
          you under the Data Protection Act 1998. A small fee may be payable. If
          you would like a copy of the information held on you please write to
          contact@flixstock.com
        </p>

        <p className=" para_gap">
          If you believe that any information we are holding on you is incorrect
          or incomplete, please login to your account and update your profile or
          write to us as soon as possible, at contact@flixstock.com. We will
          promptly correct any information found to be incorrect.
        </p>
      </div>
    </div>
  );
};

export default PolicyPage;
