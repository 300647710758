import { Button, Card, Col, Row, Segmented, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import "./mainStyle.scss";
import { useCaseData } from "./useCaseData";
const { Title, Text } = Typography;
const MainPage = ({ setBookingFlag }) => {
  const [useCase, setUseCase] = useState("Brands");
  const studioCardColors = [
    "#D9CFFF",
    "#FBD7A3",
    "#AAD8DB",
    "#E4B9F5",
    "#ffa9a9",
  ];
  const [activeCase, setActiveCase] = useState([]);
  const [activeStep, setActiveStep] = useState("studio");
  const [currentWork, setCurrentWork] = useState(0);
  const [youtubeId, setYoutubeId] = useState("eK3UBQa4eyg");
  // const [loadFlag, setLoadFlag] = useState(false);
  const clients = [
    // "images/newBrand1.svg", //jockey
    "images/newBrand17.svg",
    "images/newBrand16.svg",
    "images/newBrand15.svg",
    "images/newBrand14.svg",
    "images/newBrand13.svg",
    "images/newBrand12.svg",
    "images/newBrand11.svg",
    "images/newBrand10.svg",
    "images/newBrand9.svg",
    "images/newBrand8.svg",
    "images/newBrand7.svg",
    "images/newBrand6.svg",
    "images/newBrand5.svg",
    "images/newBrand4.svg",
    "images/newBrand2.svg",
    "images/newBrand3.svg",
  ];

  const prodData = [
    {
      name: "studio",
      image: "images/FlixStudio.gif",
      logo: "images/FlixStudio.svg",
      logoText: "End to End Photo Shoot Solution",
      description:
        "Transform your model shoots with FlixStudio's streamlined platform, ensuring clear progress and successful model shoots every time",
    },
    {
      name: "model",
      image: "images/FlixModel.gif",
      logo: "images/FlixModel.svg",
      logoText: "On-Mannequin to Model",
      description:
        "FlixModel specialises in high volume photography, providing fast and cost-effective bulk photoshoots for product images, perfect for those with an in-house studio.",
    },
    {
      name: "3d",
      image: "images/Flix3d.gif",
      logo: "images/Flix3D.svg",
      logoText: "Get image from any stage of PD",
      description:
        "Flixstock provides a full product visualisation solution, from generating images from tech-pack to metaverse exploration, including 360 views and virtual walkthroughs.",
    },
    {
      name: "lite",
      image: "images/FlixLite.gif",
      logo: "images/FlixLite.svg",
      logoText: "Unlimited Model | Self Served | DIY",
      description:
        "Flixlite offers A/B testing, self-served image editing, and catalog making, with a focus on improved conversions and refreshed catalogs.",
    },
    {
      name: "plus",
      image: "images/FlixPlus.gif",
      logo: "images/FlixPlus.svg",
      logoText: "Bulk Creative Generation",
      description:
        "Generate a lot more creative in less time and effort, Generate controlled creative for multiple social media platforms at once, bulk creative generator",
    },
    {
      name: "edit",
      image: "images/ImageEdit.gif",
      logo: "images/IE.svg",
      logoText: "Editing Bots & Workflows",
      description:
        "Image edit provides AI editing bots and workflows for fast and consistent background removal, retouching, resizing, and enhancing of product images.",
    },
  ];

  const videoList = [
    {
      name: "model",
      videoId: "OEw2yuNO3_Y",
    },
    {
      name: "studio",
      videoId: "eK3UBQa4eyg",
    },
    {
      name: "3d",
      videoId: "9z6ZUhi4UO8",
    },
    {
      name: "lite",
      videoId: "R7QRNEHJ3Cg",
    },
    {
      name: "plus",
      videoId: "xi6gDOqzYpU",
    },
    {
      name: "edit",
      videoId: "myDmqQ10q50",
    },
  ];
  const handleWork = (key, index) => {
    const tempId = videoList.find((el) => el.name == key);
    setYoutubeId(tempId.videoId);
    setCurrentWork(index);
    // setLoadFlag(false)
  };
  useEffect(() => {
    if (useCaseData) {
      let newData = useCaseData.find(
        (el) => el.name == activeStep.toLocaleLowerCase()
      );
      let type = useCase.toLocaleLowerCase();
      let finalData = newData[type];
      setActiveCase(finalData);
    }
  }, [useCase, activeStep]);

  return (
    <div id="mainPage" className="main-page">
      <div id="headTitle" className="title_box common-container">
        <Title className="title_text">Helping Online Sellers Create</Title>
        <Title className="image-text title_text">
          High-Quality On-Model Images
        </Title>
        <Title level={4} className="title_text_underheading">
          "We offer a suite of products that help you enhance your customer's
          image experience at every touchpoint of their buying journey."
        </Title>
        <div className="w-75 flex-between mx-auto mt-3 title_img_box">
          <div className="flexAlign-center text-icons-container">
            <img src="images/handshake.svg" alt="handshake" />
            <div
              direction="vertical"
              align="start"
              className="ml-2 text-left text-icon-space"
            >
              <Text strong>346</Text>
              <Text>Clients Worldwide</Text>
            </div>
          </div>
          <div className="flexAlign-center text-icons-container">
            <img src="images/landscape.svg" alt="landscapeicon" />
            <div
              direction="vertical"
              align="start"
              className="ml-2 text-left text-icon-space"
            >
              <Text strong>15M+</Text>
              <Text>Images Processed</Text>
            </div>
          </div>
          <div className="flexAlign-center text-icons-container">
            <img src="images/checkmark.svg" alt="checkamrkicon" />
            <div
              direction="vertical"
              align="start"
              className="ml-2 text-left text-icon-space"
            >
              <Text strong>97%</Text>
              <Text>Pass Percentage </Text>
            </div>
          </div>
        </div>
        <Button
          className="try_btn mt-3 mx-auto"
          type="ghost"
          onClick={() => setBookingFlag(true)}
        >
          Find the right product for you{" "}
          <img src="images/arrowRight.svg" alt="rightArrow" />{" "}
        </Button>
      </div>

      <div className="image_collage flexAlign-center w-100 collage_web ">
        <img
          src="images/webCollage.png"
          alt="model-collage"
          className="webCollage_img"
        />
      </div>

      <div className="image_collage flexAlign-center w-100 collage_mob ">
        <img
          src="images/mobCollage.png"
          alt="model-collage"
          className="mobCollage_img"
        />
      </div>

      <div className="clients_box fold-gap common-container">
        <Title className="client_heading">Our Global Clientele</Title>
        <Text className="primary_size">
          As a global company, Flixstock has the privilege of working with a
          diverse range of clients, from small startups to multinational
          corporations.
        </Text>
        <Title level={3} className="color-primary globe_text">
          350+ Clients Across Globe
        </Title>
        <div className="client_logo_container d-flex mt-4">
          {clients.map((el) => {
            return (
              <Card className="logo_cards">
                <img src={el} className="logo_imgs" alt={el} />
              </Card>
            );
          })}
        </div>
      </div>

      <div id="productSuite" className="product_box fold-gap common-container">
        <img
          src="images/productSuite.svg"
          className="productTitle_img"
          alt="prodSuite"
        />
        <div className="flex-between mt-1 w-50 mx-auto underProd_box">
          <div className="blueLine" />
          <Text className="prod_subText">
            One-Stop-Shop for all your cataloging needs.
          </Text>
          <div className="blueLine" />
        </div>
        <Text className="prod_title_description w-50 mx-auto mt-1">
          Flixstock's product suite creates catalog images for sales and
          marketing from any stage of product development, be it using tech pack
          to generate catalog images or handling your shoot end-to-end, or
          generating up to 1000 images a day.
        </Text>
        <Row
          gutter={[
            { xs: 24, xl: 58 },
            { xs: 16, md: 32, xl: 32 },
          ]}
          className="mt-4"
        >
          {prodData.map((elm) => {
            return (
              <Col xs={{ span: 12 }} xl={{ span: 8 }} className="prod_col">
                <Space direction="vertical" split className="prod_col_card">
                  <img
                    src={elm.image ? elm.image : "images/tempGif.svg"}
                    className="prod_gif"
                    alt={elm.image}
                  />
                  <div className="prod_brands flex-between flexAlign-center py-2">
                    <img src={elm.logo} alt={elm.logo} />
                    <Text className="color-primary">{elm.logoText}</Text>
                  </div>
                  <Text
                    className="text-left pord_description"
                    style={{ display: "block", fontSize: "0.85rem" }}
                  >
                    {elm.description}
                  </Text>
                </Space>
              </Col>
            );
          })}
        </Row>
      </div>

      <div className="partner_box fold-gap common-container">
        <Space direction="vertical">
          <Title className="partner_title">
            "Unlock your brand's full potential"{" "}
          </Title>
          <Title className="partner_subTitle">
            Benefits of Partnering with us
          </Title>
        </Space>
        <Row className="mt-2">
          <Col xl={{ span: 6, offset: 2 }} xs={{ span: 24 }}>
            <div className="benifit_card flex-col">
              <Text className="backLay_num backLay_num_1 num_web ">01</Text>
              <Text className="backLay_num_1 num_mob ">01</Text>
              <Space.Compact
                className="benifit_text_container mt-4"
                direction="vertical"
              >
                <Text className="benifit_title" strong italic>
                  Faster go to <br />
                  Market
                </Text>
                <img
                  src="images/line1.svg"
                  className="color_divider"
                  style={{ width: "2vw" }}
                  alt="line1"
                />
                <Text className="benifit_description">
                  Flixstock's advanced e-commerce imaging services, including
                  super-fast editing, 3D sampling, and on-model image creation,
                  help you accelerate your market entry for garment sales.
                </Text>
              </Space.Compact>
            </div>
          </Col>

          <Col xl={{ span: 6, offset: 2 }} xs={{ span: 24 }}>
            <div className="benifit_card flex-col">
              <Text className="backLay_num backLay_num_2 num_web ">02</Text>
              <Text className="backLay_num_2 num_mob ">02</Text>
              <Space.Compact
                className="benifit_text_container mt-4"
                direction="vertical"
              >
                <Text className="benifit_title benifit_title_web" strong italic>
                  Tailored imaging solutions for <br /> diverse business needs
                </Text>
                <Text className="benifit_title benifit_title_mob" strong italic>
                  Tailored imaging solutions for diverse business needs
                </Text>
                <img
                  src="images/line2.svg"
                  style={{ width: "2vw" }}
                  alt="line2"
                  className="color_divider"
                />
                <Text className="benifit_description">
                  We offer customised imaging solutions for brands, retailers,
                  and marketplaces, catering to their specific needs and as per
                  their cataloging requirements.
                </Text>
              </Space.Compact>
            </div>
          </Col>
          <Col xl={{ span: 5, offset: 2 }} xs={{ span: 24 }}>
            <div className="benifit_card flex-col">
              <Text className="backLay_num backLay_num_3 num_web">03</Text>
              <Text className="backLay_num_3 num_mob">03</Text>
              <Space.Compact
                className="benifit_text_container mt-4"
                direction="vertical"
              >
                <Text className="benifit_title" strong italic>
                  Effortlessly Create <br /> Images at Scale{" "}
                </Text>
                <img
                  src="images/line3.svg"
                  style={{ width: "2vw" }}
                  alt="line3"
                  className="color_divider"
                />
                <Text className="benifit_description">
                  FlixStock's AI technology allows for easy creation of
                  eCommerce & Creative images on a large scale.
                </Text>
              </Space.Compact>
            </div>
          </Col>
        </Row>
      </div>

      <div id="useCase" className="useCase_box fold-gap common-container">
        <Title level={4} className="color-primary">
          Use Cases
        </Title>
        <Title className="useCase_title">
          "Find a perfect product which suits your needs”
        </Title>
        <Text className="w-50 mx-auto d-block mt-1 useCase_subtitle">
          We offer multiple imaging solutions for diverse clients, from startups
          to multinational corporations, Solution are curated to cater use cases
          of Fashion brand, retailer, and marketplaces. Check out the product
          offerings for a solution that suits your needs.
        </Text>
        <Segmented
          className="mt-2 w-50 mx-auto custom_segment"
          size="large"
          block
          options={["Brands", "Marketplace", "Retailers"]}
          onChange={(el) => setUseCase(el)}
          value={useCase}
        />
        <div className="slider_box mt-2">
          <div className="slider_line" />
          <div className="slider_prod">
            {prodData.map((elm, index) => {
              return (
                <div
                  className={
                    activeStep == elm.name
                      ? "active_prod_div noraml_slider_box"
                      : "noraml_slider_box"
                  }
                >
                  {" "}
                  <img
                    src={elm.logo}
                    className={
                      activeStep == elm.name
                        ? "slider_prod_img active_prod"
                        : "slider_prod_img "
                    }
                    onClick={() => setActiveStep(elm.name)}
                    alt={elm.logo}
                  />{" "}
                </div>
              );
            })}
          </div>
        </div>
        <div className="slide_box_mob mt-2 flex-between">
          {prodData.map((elm, index) => {
            return (
              <div
                className={
                  activeStep == elm.name
                    ? "mob_active_prod_div mob_noraml_slider_box"
                    : "mob_noraml_slider_box"
                }
              >
                {" "}
                <img
                  src={elm.logo}
                  className={
                    activeStep == elm.name
                      ? "mob_slider_prod_img mob_active_prod"
                      : "mob_slider_prod_img "
                  }
                  onClick={() => setActiveStep(elm.name)}
                  alt={elm.logo}
                />{" "}
              </div>
            );
          })}
        </div>
        <Row className="case_row mt-4" gutter={[16, 16]}>
          {activeCase.map((elm, index) => {
            return (
              <Col xl={{ span: 6 }} xs={{ span: 12 }}>
                <Card
                  className="case_card text-left flex-col"
                  style={{
                    border: `0.5px solid ${studioCardColors[index]}`,
                    height: "100%",
                  }}
                >
                  <div
                    className="num_circle mb-1"
                    style={{ backgroundColor: `${studioCardColors[index]}` }}
                  >
                    0{index + 1}
                  </div>
                  <Text className="case_card_text">{elm}</Text>
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>

      <div id="howItWorks" className="how_works fold-gap common-container">
        <div className="flexAlign-center flex-between work_header_cont">
          <Space direction="vertical" align="start">
            <img src="images/works.svg" alt="workImage" />
            <Text className="w-75 d-block text-left work_subTitle">
              From Retailers to Marketplaces to Fashion Brands of all sizes, Our
              Solutions are Tailored to Fit All Players in the Industry.
            </Text>
          </Space>

          <Space className="x_imgs">
            <img src="images/5x.png" alt="5ximg" className="size_imgs" />
            <img src="images/10x.png" alt="10ximg" className="size_imgs" />
          </Space>
        </div>
        <Row className="mt-3 video_row w-100" gutter={[16, 16]}>
          <Col xl={{ span: 4 }} xs={{ span: 24 }} style={{ paddingLeft: 10 }}>
            <ul className="custom_menu">
              {prodData.map((el, index) => {
                return (
                  <li
                    className={
                      index == currentWork
                        ? "work_list_item text-left active_item"
                        : "work_list_item text-left"
                    }
                    onClick={() => handleWork(el.name, index)}
                  >
                    <img src={el.logo} alt={el.logo} />
                  </li>
                );
              })}
            </ul>
          </Col>
          <Col xl={{ span: 16, offset: 4 }} xs={{ span: 24 }}>
            <iframe
              title="Youtube player"
              sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
              src={`https://youtube.com/embed/${youtubeId}?controls=0&modestbranding=1&showinfo=0&fs=0&rel=1`}
              style={{ aspectRatio: "16/10", width: "100%", height: "60vh" }}
              // onLoad={(e)=>setLoadFlag(true)}
              className="video_player"
            ></iframe>
          </Col>
        </Row>
      </div>

      {/* <div className="common-container call_box fold-gap w-100 mx-auto">
        <Row>
          <Col
            xl={{ span: 10 }}
            sm={{ span: 24 }}
            className=" flexAlign-center text-left"
          >
            <Title level={3} className="call_box_title">
              Sign Up Now & Get a Call from Our Experts!
            </Title>
          </Col>
          <Col
            xl={{ span: 10, offset: 4 }}
            sm={{ span: 24 }}
            className="call_input_col flexAlign-center"
          >
            <Input
              className="input_call_box inner_call_box"
              placeholder="Enter email id"
            />
            <Button className="btn_call_box inner_call_box">
              Talk to Expert
            </Button>
          </Col>
        </Row>
      </div>
      <div className="px-4 fold-gap">
        <div className="hr_bar"></div>
      </div> */}
    </div>
  );
};

export default MainPage;
