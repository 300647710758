import { Button, Space } from "antd";
import React from "react";
import './siteHeader.scss';
import { Link } from "react-router-dom";

const SiteHeader = () => {
  let list = [
    {
      name: "FlixStock",
      link: "https://www.flixstock.com/",
    },
    {
      name: "FlixStudio",
      link: "https://www.flixstudio.io/",
    },
    {
      name: "ImageEdit.ai",
      link: "https://imageedit.ai/",
    },
  ];
  return (
    <div className="w-100 site-header px-4">
      <Space>
        {list.map((elm) => {
          return (
            <Button
              type={ "secondary"}
              shape="round"
              size="small"
              className={window?.location?.origin?.toLocaleLowerCase()?.includes(elm?.name?.toLocaleLowerCase())? "site-btn-active": 'site-btn-inactive'}

            >
              <Link to={elm.link}>{elm.name}</Link>
            </Button>
          );
        })}
      </Space>
    </div>
  );
};

export default SiteHeader;
