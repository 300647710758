import React from 'react';
import Home from '../../components/Home';
const HomePage = ({setBookingFlag}) =>{
    return(
        <div className='home_page'>
          <Home setBookingFlag={setBookingFlag}/>
        </div>
    )
}

export default HomePage;