import { Button, Space } from "antd";
import React from "react";

const ThankyouPage = (props) => {
  return (
    <div className="thankyou_page">
      <img src="/images/thankyou.svg" />
      <Space direction="vertical" align="center">
        <h1>Thank You</h1>
        <span className="form_description">
          Our Expert will connect you soon
        </span>
      </Space>
      <Space direction="vertical" align="center" className="mt-1">
      <span style={{fontSize:'11px', color:'#5571ED'}}>You can also schedule a meeting with our expert</span>
     {!props.redirected&& <Button
        className="form-btns form-btn-1"
        style={{width:'250px', marginTop:0}}
        onClick={() => props.redirectToCalendly('callback')}
      >
        Book a meeting
      </Button>}

      <Button
        type="ghost"
        style={{ color: "#a0a3bd" }}
        onClick={() => props.closeModal()}
      >
        Continue
      </Button>
      </Space>
     
    </div>
  );
};

export default ThankyouPage;
